<template>
  <div class="comments-container">
    <SectionHeader title="Explanatory Comments" />
    <div class="dateFilter">
      From:
      <input type="date" name="search" v-model="dateRange.start" />
      To:
      <input type="date" name="search" v-model="dateRange.end" />
      <br />
      Theme:
      <select class="theme-select" v-model="selectedThemeNumber">
        <option disabled value="false"> - </option>
        <option v-for="theme in themes" :key="theme.theme" :value="theme.theme">
          {{ theme.theme }}: {{ theme.title }}
        </option>
      </select>
    </div>
    <div class="section-list" v-if="!selectedTheme">
      <p>Please select a theme</p>
    </div>
    <div class="section-list" v-if="selectedTheme">
      <div
        class="section-item"
        v-for="(question, index) in selectedTheme.questions"
        :key="index"
        @click="selectQuestion(index)"
      >
        <p class="section-item__name">{{ index + 1 }}: {{ question.text }}</p>
        <p class="section-item__count">
          {{ commentCountLabel(commentCountByThemeQuestion[index]) }}
        </p>
        <input
          type="checkbox"
          name="check"
          id=""
          :checked="selectedQuestions.find((i) => i === index)"
        />&nbsp;
        <label for="check">Add to report</label>
      </div>
    </div>
    <div class="results-table">
      <table>
        <thead></thead>
        <tbody v-if="selectedTheme">
          <tr v-for="questionIndex in selectedQuestions" :key="questionIndex">
            <p class="section-id" v-if="questionIndex">
              {{ questionIndex + 1 }}:
              {{ selectedTheme.questions[questionIndex].text }}
            </p>
            <ul v-if="questionIndex">
              <li
                v-for="(comment, index) in questionComments(questionIndex)"
                :key="questionIndex"
              >
                {{ comment }}
              </li>
            </ul>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'

import { mapGetters, mapActions } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'ExplanatoryComments',
  components: { SectionHeader },
  data() {
    return {
      selectedThemeNumber: false,
      selectedTheme: null,
      selectedQuestions: [],

      dateRange: {
        start: 0,
        end: 0,
      },
    }
  },
  filters: {
    formatDate(val) {
      return format(new Date(val), 'PPP')
    },
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      users: 'organisation/users',
      reviews: 'assignment/reviews',
      themes: 'criteria/themes',
    }),
    reviewReports() {
      const reviews =  this.reviews
        .filter((review) => review.outcome && review.completedAt && !review?.assignment?.archived) 
        .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
        return reviews
    },
    reviewsFrom() {
      return new Date(this.dateRange.start)
    },
    reviewsTo() {
      return new Date(this.dateRange.end)
    },
    commentCountByThemeQuestion() {
      const counts = {
        1: {
          count: 0,
          comments: [],
        },
      }

      const answers = this.reviewReports
        .map(({ answers, completedAt }) =>
          answers.filter(({ theme }) => theme == this.selectedThemeNumber).map(a=>({...a, completedAt}))
        )
        .flat(1)
        .map(({ criteria, completedAt}) => criteria.map(a=>({...a,completedAt})))
        .flat(1)
        .filter(({ comments, completedAt }) => {
          if(!comments) return false;
          const reviewCompleted = new Date(completedAt)
          const completedDateValue = reviewCompleted.valueOf()
          const eodReviewsTo = this.reviewsTo.setHours(23, 59, 59, 999);
          if (this.reviewsFrom.valueOf() > 0 && completedDateValue <= this.reviewsFrom.valueOf())
            return false
          console.log(new Date(completedDateValue), new Date(eodReviewsTo))
          if (this.reviewsTo.valueOf() > 0 && completedDateValue >= eodReviewsTo.valueOf())
            return false
          return true
        })
        .map(({ questionIndex, comments }) => ({ questionIndex, comments }))

      answers.forEach(({ questionIndex, comments }) => {
        if (!counts[questionIndex]) {
          counts[questionIndex] = {
            count: 0,
            comments: [],
          }
        }
        counts[questionIndex].count++
        counts[questionIndex].comments.push(comments) //.push({ text: theme.comments, date: review.completedAt })
      })

      return counts
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: 'organisation/fetchUsers',
      fetchAssignments: 'assignment/fetchAssignments',
    }),
    questionComments(i) {
      return this.commentCountByThemeQuestion[i].comments
    },
    selectQuestion(questionIndex) {
      const commentCount = this.commentCountByThemeQuestion[questionIndex]
      if (!commentCount || !commentCount.count) {
        return
      }
      if (this.selectedQuestions.find((i) => i === questionIndex)) {
        this.selectedQuestions = this.selectedQuestions.filter(
          (i) => i !== questionIndex
        )
        return
      }
      this.selectedQuestions.push(questionIndex)
    },
    commentCountLabel(countObj) {
      if (!countObj || !countObj.count || countObj.count == 0)
        return 'No comments'
      const { count } = countObj
      if (count == 1) return '1 comment'
      return `${count} comments`
    },
  },
  async mounted() {
    if (!this.reviews.length) {
      await this.fetchAssignments()
    }
  },
  watch: {
    selectedThemeNumber() {
      this.selectedTheme = this.themes.find(
        ({ theme }) => theme == this.selectedThemeNumber
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';
@import '@/assets/styles/sections.scss';
@import '@/assets/styles/modal.scss';

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

.section-list {
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.section-item {
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  transition: transform 150ms ease-in-out;
  &__name {
    font-weight: 600;
  }
  &__count {
    font-style: italic;
  }
  &:hover {
    transform: scale(1.025);
  }
}

.section-id {
  font-weight: 600;
  font-style: italic;
}

select.theme-select {
  margin-top: 12px;
  height: 30px;
}
</style>
