<template>
<div class="plan-container plan-container__assign-audit">
  <p class="title">Export Audits</p>
  <div class="sub-field">
    <p class="subtitle">Select a date range to export audit data.</p>
    <div class="search-params">
      <div class="dateFilter">
        From:
        <input type="date" name="search" v-model="dateRange.start" />
        To:
        <input type="date" name="search" v-model="dateRange.end" />
      </div>
      <div class="search-bar field no-margin">
        <button
          class="button"
          @click="downloadReport"
          :disabled="isDownloading || !dateRange.start || !dateRange.end"
        >
          Download Report
        </button>
      </div>
    </div>
  </div>
  <div class="plan-list">
  <div class="table-wrapper">
    <table class="table">
      <thead>
        <tr class="table-header">
          <th>*C/YP ID</th>
          <th>Auditor</th>
          <th>Assigned on</th>
          <th>Completed on</th>
          <th>Outcome</th>
        </tr>
      </thead>
      <tbody name="table-row" is="transition-group">
        <tr v-for="audit in filteredAudits" :key="audit.id">
          <td>{{ audit.assignment.plan.internalId }}</td>
          <td>{{ audit.assignment.user.name }}</td>
          <td>{{ audit.createdAt | formatDate }}</td>
          <td>{{ audit.completedAt | formatDate }}</td>
          <td>{{ audit.outcome | formatOutcome }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { decode } from 'base-64'
import { utf8 } from 'utf8'
import { outcomeGrades, formatGrades, getGradingColor  } from '../../_helpers/grading'


export default {
  name: 'AuditExport',
  data () {
    return {
      dateRange: {
        start: null,
        end: null
      },
      isDownloading: false
    }
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      audits: 'assignment/reviews'
    }),
    filteredAudits () {
      const sortedAudits = [...this.audits].slice()
        .filter(a => !!a?.completedAt && !a?.assignment?.archived && !!a?.outcome)
        .sort((a, b) => b.completedAt > a.completedAt ? 1 : -1)

      if (!this.dateRange.start || !this.dateRange.end) {
        return sortedAudits
      }

        return sortedAudits.filter(x => {
        const targetDate = new Date(x.completedAt).getTime()
        const startDate = new Date(this.dateRange.start).setHours(0, 0, 0, 0)
        const endDate = new Date(this.dateRange.end).setHours(23, 59, 59, 999)
        return targetDate >= startDate && targetDate <= endDate
      })
      
     
    }
  },
  filters: {
    formatDate (val) {
      if (!val) {
        return ''
      }
      return format(new Date(val), 'PPP')
    },
     formatOutcome (val) {
      const gradingObj = outcomeGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= val && val <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    }
  },
  methods: {
    async downloadReport () {
      this.isDownloading = true
      const { data: base64String } = await this.ApiService
        .getReportForAuditsInRange(this.dateRange.start, this.dateRange.end)

   console.log("String ", base64String);
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fileName = "vct_illustration.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

      this.isDownloading = false
    },


    convertBase64StringToBlob (base64String) {
    var bytes = decode(base64String);
    var text = utf8.decode(bytes);
    return text
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/style/components.scss";
@import "@/style/sections.scss";

.upload-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 46px repeat(3, auto);
  grid-auto-flow: unset;
  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    padding-left: 1rem;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
    &--bold {
      font-weight: 600;
    }
  }
  &__dropbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px dashed lightgrey;
    border-radius: $border-radius;
    padding: 1rem;
    margin: 1rem;
    font-size: 1.25rem;
  }
  .is-active {
    background: #42ab983b;
  }
  .button-row {
    padding-top: $padding1x;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

td.archive {
  width: 3rem;
  // background: rgba(0, 0, 0, 0);
  color: transparent;
  transition: all 200ms ease-in-out;
}

.table-row-enter-active,
.table-row-leave-active {
  transition: all 350ms;
}

.table-row-enter,
.table-row-leave-to {
  opacity: 0;
}

.table-row-move {
  transition: all 300ms;
}

td.archive > .fa-2x {
  font-size: 1.5rem;
}

.table .table-header > th {
  // color: red;
  cursor: pointer;
}

.table > tbody > tr:hover {
  .archive {
    background: rgba(232, 49, 86, 1);
    color: white;
  }
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sub-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: search;
  .subtitle {
    width: 100%;
  }
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.search-bar {
  max-width: 200px;
}

button.button:disabled {
  background: lighten($color: $brand-green, $amount: 25%);
  cursor: not-allowed;
}
</style>
