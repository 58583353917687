<template>
  <div class="custom-graphs">
    <SectionHeader title="Custom Graphs" />
    <div>
      <button style="float:right" class="button" @click="exportToCSV">Download CSV</button>
     </div>
    <div class="graphs">
      <CustomGraph
        title="Primary area of need"
        :keyStages="keyStages"
        v-on:update:key-stage="keyStageKey = $event"
        v-on:update:period="periodNeedsKey = $event"
      >
        <template v-slot:options-select>
          <div class="options-select">
            <p>View Primary area of need:</p>
            <div class="select">
              <select v-model="needsKey">
                <option value="all">All</option>
                <option v-for="need in needs" :key="need" :value="need">
                  {{ need }}
                </option>
              </select>
            </div>
          </div>
        </template>
        <template v-slot:graph-area>
          <div class="graphing" ref="graph-need" />
        </template>
      </CustomGraph>
      <div>
      <button style="float:right" class="button" @click="SectionDataToExportToCSV">Download CSV</button>
     </div>
      <CustomGraph
        title="Section of Annual Review"
        :keyStages="keyStages"
        v-on:update:key-stage="sectionKeyStageKey = $event"
        v-on:update:period="periodSectionKey = $event"
      >
        <template v-slot:options-select>
          <div class="options-select">
            <p>View Section:</p>
            <div class="select">
              <select v-model="sectionKey">
                <option value="1">Timeliness & Quality</option>
                <option value="2">Meeting Needs</option>
                <option value="3">Outcomes</option>
                <option value="4">Provision</option>
                <option value="5">Post-annual review</option>
                <option value="6">Outcomes monitoring</option>
              </select>
            </div>
          </div>
        </template>
        <template v-slot:graph-area>
          <div id="graph-section" class="graphing" ref="graph-section" />
        </template>
      </CustomGraph>
      <div>
      <button style="float:right" class="button" @click="exportToCSV">Download CSV</button>
     </div>
      <CustomGraph
        title="Social Care Input"
        :keyStages="keyStages"
        v-on:update:key-stage="inputKeyStageKey = $event"
        v-on:update:period="periodInputKey = $event"
      >
        <template v-slot:graph-area>
          <div class="graphing" ref="graph-input"></div>
        </template>
      </CustomGraph>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import CustomGraph from './CustomGraph'
import jsonexport from 'jsonexport'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import { mapGetters, mapActions } from 'vuex'
import { colorLookup, labelOrder } from '../../style/gradingValues'
import { outcomeGrades, formatGrades, formatTheme6Grades, getGradingColor  } from '../../_helpers/grading'


const isDateBetweenCurry = (period) => (date) => {
  if (!date || !period || !period.length === 2) return true
  if (isAfter(date, period[0]) && isBefore(date, period[1])) return true
  return false
}

const graphFont = {
  family: 'AllRoundGothicBook',
  size: '16',
  color: 'white'
}

export default {
  name: 'CustomGraphs',
  components: { SectionHeader, CustomGraph },
  data () {
    return {
      needsKey: 'all',
      sectionKey: '1',
      keyStageKey: 'all',
      sectionKeyStageKey: 'all',
      inputPeriodKey: 'all',
      inputKeyStageKey: 'all',
      periodNeedsKey: 'all',
      periodSectionKey: 'all',
      periodInputKey: 'all'
    }
  },
  watch: {
    reviews: {
      handler: function () {
        Plotly.react(
          this.$refs['graph-need'],
          this.primaryNeedTraces,
          {},
          { displaylogo: false, responsive: true, autosizable: true }
        )

        Plotly.react(
          this.$refs['graph-section'],
          this.auditSectionTraces,
          { xaxis: { autorange: true } },
          { displaylogo: false, responsive: true, autosizable: true }
        )

        Plotly.react(
          this.$refs['graph-input'],
          this.careInputTrace,
          { barmode: 'stack' },
          { displaylogo: false, responsive: true, autosizable: true }
        )
      },
      deep: true
    },
    periodNeedsKey () {
      Plotly.react(
        this.$refs['graph-need'],
        this.primaryNeedTraces,
        {},
        { displaylogo: false, responsive: true, autosizable: true }
      )
    },
    periodSectionKey () {
      Plotly.react(
        this.$refs['graph-section'],
        this.auditSectionTraces,
        { xaxis: { autorange: true } },
        { displaylogo: false, responsive: true, autosizable: true }
      )
    },
    periodInputKey () {
      Plotly.react(
        this.$refs['graph-input'],
        this.careInputTrace,
        { barmode: 'stack' },
        { displaylogo: false, responsive: true, autosizable: true }
      )
    },
    needsKey () {
      Plotly.animate(this.$refs['graph-need'],
        {
          data: this.primaryNeedTraces,
          layout: {
            title: 'Gradings by primary area of need'
          },
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )
    },
    keyStageKey (newVal) {
      Plotly.animate(this.$refs['graph-need'],
        {
          data: this.primaryNeedTraces,
          layout: {},
          config: { responsive: true, autosizable: true }
        },
        {
          transition: { duration: 500, easing: 'cubic-in-out' },
          frame: { duration: 500 }
        }
      )
    },
    sectionKey (newVal) {
      Plotly.animate(
        this.$refs['graph-section'],
        {
          data: this.auditSectionTraces,
          layout: {
            xaxis: {
              range: Object.keys(this.auditSectionData).map((a, idx) => idx)
            }
          },
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 300,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 300
          }
        }
      )
      setTimeout(() => {
        Plotly.relayout(this.$refs['graph-section'], {
          'xaxis.autorange': true,
          'yaxis.autorange': true
        })
      }, 300)
    },
    sectionKeyStageKey (newVal) {
      Plotly.animate(
        this.$refs['graph-section'],
        {
          data: this.auditSectionTraces,
          layout: {
            xaxis: {
              range: Object.keys(this.auditSectionData).map((a, idx) => idx)
            }
          },
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 300,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 300
          }
        }
      )
      setTimeout(() => {
        Plotly.relayout(this.$refs['graph-section'], {
          'xaxis.autorange': true,
          'yaxis.autorange': true
        })
      }, 300)
    },
    inputKeyStageKey (newVal) {
      Plotly.animate(this.$refs['graph-input'],
        {
          data: this.careInputTrace,
          layout: this.layout,
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )
    }
  },
  computed: {
    ...mapGetters({ reviews: 'assignment/reviews' }),
    needs () {
      return new Set(this.reviews.map(r => r.assignment.plan.primaryNeed))
    },
    keyStages () {
      return new Set(this.reviews.map(r => r.assignment.plan.keyStage))
    },
    //TODO: Remove this object once final outcome functionality is integrated
    // fakeCompletedReviews () {
    //   return this.reviews.map(review => ({
    //     ...review,
    //     completedAt: '2021-03-12T08:26:31.806Z',
    //     outcome: Math.floor(Math.random() * 15) + 1
    //   }))
    // },
    completedReviews () {
      let reviews = this.reviews.filter(r => r?.completedAt && r?.outcome && !r?.assignment?.archived)

      return reviews
    },
    primaryNeedData () {
      if (!this.needsKey || !this.keyStageKey) {
        return {}
      }

       let reviews = this.completedReviews
        .filter(r => this.needsKey === 'all'
          ? true : r.assignment.plan.primaryNeed === this.needsKey)
        .filter(r => this.keyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.keyStageKey)
        if (this.periodNeedsKey != 'all') {
          const dates = JSON.parse(this.periodNeedsKey).map(v => new Date(v))
          const isDateBetween = isDateBetweenCurry(dates)
          reviews = reviews.filter(({ completedAt }) => isDateBetween(new Date(completedAt)))
      }

      return this.generateOutcomeData(reviews)
    },
    primaryNeedTraces () {
      return [{
        values: Object.values(this.primaryNeedData),
        labels: Object.keys(this.primaryNeedData),
        type: 'pie',
        sort: false,
        hole: 0.6,
        textfont: graphFont,
        hoverlabel: {
          font: graphFont
        },
        marker: {
          colors: Object.keys(this.primaryNeedData).map(k => colorLookup[k]),
          line: {
            width: 10,
            color: '#FFF'
          }
        }
      }]
    },
    auditSectionData () {
      let reviews = this.completedReviews
        .filter(r => this.sectionKeyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.sectionKeyStageKey)
      if (this.periodSectionKey != 'all') {
        const dates = JSON.parse(this.periodSectionKey).map(v => new Date(v))
        const isDateBetween = isDateBetweenCurry(dates)
        reviews = reviews.filter(({ completedAt }) => isDateBetween(new Date(completedAt)))
      }
      return this.generateSectionOutcomeData(reviews.map(review => {
          // review.answers = review.answers.filter(answer => answer.theme === Number(this.sectionKey))
          return {
            ...review,
            section: review.answers.find(section => section.theme === Number(this.sectionKey))
          }
        }))
      // return {

      // }
    },
    auditSectionTraces () {
      return [{
        x: Object.keys(this.auditSectionData),
        y: Object.values(this.auditSectionData),
        labels: Object.keys(this.auditSectionData),
        type: 'bar',
        sort: false,
        textfont: graphFont,
        hoverlabel: {
          bordercolor: Object.keys(this.auditSectionData).map(k => colorLookup[k]),
          font: graphFont
        },
        marker: {
          color: Object.keys(this.auditSectionData).map(k => colorLookup[k])
        }
      }]
    },
    careInputData () {
      let reviews = this.completedReviews
        .filter(r => this.inputKeyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.inputKeyStageKey)
        if (this.periodInputKey != 'all') {
          const dates = JSON.parse(this.periodInputKey).map(v => new Date(v))
          const isDateBetween = isDateBetweenCurry(dates)
          reviews = reviews.filter(({ completedAt }) => isDateBetween(new Date(completedAt)))
       }

      const results = reviews.map(r => ({
        input: r.assignment.plan.currentSocialCare,
        outcome: this.determineOutcome(r.outcome)
      }))

      const inputs = Array.from(new Set(results.map(i => i.input)))
      const outcomes = Array.from(new Set(results.map(i => i.outcome)))

      const traces = []
      outcomes.forEach(outcome => {
        const outcomeObject = {
          x: [],
          y: [],
          name: outcome,
          type: 'bar',
          sort: false,
          textfont: graphFont,
          hoverlabel: {
            bordercolor: colorLookup[outcome],
            font: graphFont
          },
          marker: {
            color: colorLookup[outcome]
          }
        }
        inputs.forEach(input => {
          outcomeObject.x.push(input)
          outcomeObject.y.push(results.filter(r => r.input === input && r.outcome === outcome).length)
        })
        traces.push(outcomeObject)
      })
      return traces
        .sort((a, b) => labelOrder.indexOf(a.name) - labelOrder.indexOf(b.name))
        .reverse()
    },
    careInputTrace () {
      return this.careInputData
    },
     primaryExportData () {
      if (!this.needsKey || !this.keyStageKey) {
        return []
      }
      return this.completedReviews
        .filter(r => this.needsKey === 'all'
          ? true : r.assignment.plan.primaryNeed === this.needsKey)
        .filter(r => this.keyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.keyStageKey)
        .map(a => {
          delete a.assignment.id
          delete a.assignment.organisationId
          return {
            ... a.assignment
          }
        })
    },
    auditSectionDataToExport () {
      return this.completedReviews
        .filter(r => this.sectionKeyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.sectionKeyStageKey)
        .map(a => {
          delete a.assignment.id
          delete a.assignment.organisationId
          return {
            ... a.assignment
          }
        })
    },
    careInputExportData () {
      return this.completedReviews
        .filter(r => this.inputKeyStageKey === 'all'
          ? true : r.assignment.plan.keyStage === this.inputKeyStageKey)
        .filter(a => a.outcome)
        .map(a => {
           delete a.assignment.id
           delete a.assignment.organisationId
           return {
            ... a.assignment
          }
        })
    },
  },
  methods: {
    determineOutcome (val) {
      const gradingObj = outcomeGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= val && val <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    },
    determineSectionOutcome (val) {
      const { theme, outcome } = val
      const gradingObj = theme === 6 ? formatTheme6Grades : formatGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= outcome && outcome <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    },
    generateOutcomeData (reviews) {
      const outcomes = reviews.map(r => this.determineOutcome(r.outcome))
      const keys = new Set(outcomes)
      const values = Array.from(keys)
        .flatMap(k => ({
          data: outcomes.filter(o => o === k).length,
          key: k
        }))
        .reduce((accumulated, item) => {
          return {
            ...accumulated,
            [item.key]: item.data
          }
        }, {})
      // return values
      const ordered = {}
      Object.keys(values)
        .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
        .forEach(k => (ordered[k] = values[k]))
      return ordered
    },
    generateSectionOutcomeData (reviews) {
      const outcomes = reviews.map(review => this.determineSectionOutcome(review.section.outcome))
      const keys = new Set(outcomes)
      const values = Array.from(keys)
        .flatMap(k => ({
          data: outcomes.filter(o => o === k).length,
          key: k
        }))
        .reduce((accumulated, item) => {
          return {
            ...accumulated,
            [item.key]: item.data
          }
        }, {})
      // return values
      const ordered = {}
      Object.keys(values)
        .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
        .forEach(k => (ordered[k] = values[k]))
      return ordered
    },
      async exportToCSV () {
      const data = this.primaryExportData
      const doc = await jsonexport(data)
      const newBlob = new Blob([doc])
      const blobUrl = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(doc)
    },
    async SectionDataToExportToCSV () {
      const data = this.auditSectionDataToExport
      const doc = await jsonexport(data)
      const newBlob = new Blob([doc])
      const blobUrl = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(doc)
    },
    async careInputExportDataToCSV () {
      const data = this.careInputExportData
      const doc = await jsonexport(data)
      const newBlob = new Blob([doc])
      const blobUrl = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(doc)
    }
  },
  mounted () {
    Plotly.newPlot(
      this.$refs['graph-need'],
      this.primaryNeedTraces,
      { title: 'Gradings by Primary Area of Need' },
      { displaylogo: false, responsive: true, autosizable: true, editable: true }
    )

    Plotly.newPlot(
      this.$refs['graph-section'],
      this.auditSectionTraces,
      { title: 'Individual Section Gradings', xaxis: { autorange: true } },
      { displaylogo: false, responsive: true, autosizable: true, editable: true }
    )

    Plotly.newPlot(
      this.$refs['graph-input'],
      this.careInputTrace,
      { title: 'Gradings by Social Care Input', barmode: 'stack' },
      { displaylogo: false, responsive: true, autosizable: true, editable: true }
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.options-select {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding-right: 1rem;
  }
  .select {
    select {
      display: inline-flex;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 2.5rem;
      background-color: #fff;
      border-color: #dbdbdb;
      border-radius: 4px;
      color: #363636;
      max-width: 100%;
      min-width: 150px;
      width: auto;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      padding: 0.25rem 1rem;
      line-height: 1.5;
    }
  }
}
</style>