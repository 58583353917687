<template>
  <div class="further-data">
    <SectionHeader title="Further Data" />
    <div class="plan-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>C/YP ID</th>
              <!-- <th>Auditor Name</th> -->
              <th>
                <div class="select">
                  <select v-model="selectedAuditorName">
                    <option value="all">All Auditors</option>
                    <option
                      v-for="user in users"
                      :key="user.id"
                      :value="user.id"
                    >
                      <span>{{ user.name }}</span>
                    </option>
                  </select>
                </div>
              </th>
              <th>
                <div class="select">
                  <select v-model="selectedCriteria">
                    <option value="all">All Gradings</option>
                    <option
                      v-for="gradeKey in gradeKeys"
                      :key="gradeKey"
                      :value="gradeKey"
                    >
                      <span>{{ gradeKey }}</span>
                    </option>
                  </select>
                </div>
              </th>
              <th>
                <div class="select">
                  <select v-model="selectedCaseOfficer">
                    <option value="all">All SEN Case Officers</option>
                    <option
                      v-for="officer in caseOfficers"
                      :key="officer"
                      :value="officer"
                    >
                      <span>{{
                        officer ? officer.split("@")[0] : "*not assigned*"
                      }}</span>
                    </option>
                  </select>
                </div>
              </th>
              <th>
                <div class="select">
                  <select v-model="selectedTeamRegion">
                    <option value="all">All Teams / Regions</option>
                    <option
                      v-for="entry in teamRegions"
                      :key="entry"
                      :value="entry"
                    >
                      <span>{{ entry || "*not assigned*" }}</span>
                    </option>
                  </select>
                </div>
              </th>
              <th>Date Completed</th>
            </tr>
          </thead>
          <tbody name="table-row" is="transition-group">
            <tr
              v-for="audit in auditsOnDisplay"
              :key="audit.id"
              @click="viewAuditData(audit)"
            >
              <td>{{ audit.assignment.plan.internalId }}</td>
              <td class="align-left">
                {{ audit.assignment.user.name }}
              </td>
              <!-- turn grade into a dropdown of all possible grades -->
              <td class="align-left">{{ audit.outcome | formatOutcome }}</td>
              <td class="align-left">
                {{
                  audit.assignment.plan.caseOfficer
                    ? audit.assignment.plan.caseOfficer.split("@")[0]
                    : "*not assigned*"
                }}
              </td>
              <td class="align-left">
                {{ audit.assignment.plan.teamRegion || "*not assigned*" }}
              </td>
              <td>{{ audit.completedAt | formatDateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <DataPopup
      :review="review"
      @close-review-data-popup="showAuditData = false"
      v-if="showAuditData"
    />
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import DataPopup from '@/views/admin/DataPopup.vue'

import { mapGetters, mapActions } from 'vuex'

import SearchUsersMixin from '@/_mixins/search-users.mixin'
import DateFilterMixin from '@/_mixins/date-filter.mixin'

import Fuse from 'fuse.js'

export default {
  name: 'FurtherData',
  components: { SectionHeader, DataPopup },
  mixins: [SearchUsersMixin, DateFilterMixin],
  data () {
    return {
      showAuditData: false,
      grades: {
        Gold: { min: 15, max: 100 },
        Silver: { min: 8, max: 14.999 },
        Bronze: { min: 0, max: 7.999 }
      },
      review: { plan: { internalId: null, ehcpId: null } },
      selectedCriteria: 'all',
      selectedAuditorName: 'all',
      selectedCaseOfficer: 'all',
      selectedTeamRegion: 'all',
    }
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      users: 'organisation/users',
      reviews: 'assignment/reviews'
    }),
    auditReports () {
      return this.reviews.filter((review) => review.outcome && review.completedAt)
        .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
        .map(review => {
          let colour = 'Bronze'
          Object.keys(this.grades).forEach(grade => {
            colour = this.grades[grade].min <= Number(review.outcome) && Number(review.outcome) <= this.grades[grade].max
              ? grade
              : colour
          })
          review.grade = colour
          return review
        })
    },
    gradeKeys () {
      // const availableGrades = Array.from(new Set(this.auditReports.map(a => a.outcome)))
      // return availableGrades
      return Object.keys(this.grades)
    },
    // auditors () {
    //   return [new Set(users.map(user => return { }))]
    // },
    caseOfficers () {
      return [...new Set(this.assignments.map(assignment => assignment.plan.caseOfficer).filter(co => co))]
    },
    teamRegions () {
      return [...new Set(this.assignments.map(assignment => assignment.plan.teamRegion).filter(tr => tr))]
    },
    gradeFilter () {
      return this.selectedCriteria === 'all'
        ? true
        : this.selectedCriteria
    },
    auditorFilter () {
      return this.selectedAuditorName === 'all'
        ? true
        : this.selectedAuditorName
    },
    caseOfficerFilter () {
      return this.selectedCaseOfficer === 'all'
        ? true
        : this.selectedCaseOfficer
    },
     teamRegionsFilter () {
      return this.selectedTeamRegion === 'all'
        ? true
        : this.selectedTeamRegion
    },
    auditsOnDisplay () {
      // const gradeFilter =
      // return this.selectedCriteria === 'all'
      //   ? this.auditReports
      //   : [...this.auditReports].filter(audit => audit.outcome === this.selectedCriteria)
      // TODO: Get all results to pull through when criteria is true
      const reviews = [...this.auditReports]
        .filter(audit => typeof this.auditorFilter === 'boolean'
          ? this.auditorFilter
          : audit.userId === this.auditorFilter)
        .filter(audit => typeof this.caseOfficerFilter === 'boolean'
          ? this.auditorFilter
          : audit.assignment.plan.caseOfficer === this.caseOfficerFilter)
        .filter(audit => typeof this.gradeFilter === 'boolean'
          ? this.auditorFilter
          : audit.grade === this.gradeFilter)
        .filter(audit => typeof this.teamRegionsFilter === 'boolean'
          ? this.auditorFilter
          : audit.assignment.plan.teamRegion === this.teamRegionsFilter)
          // filter out archived audits 
          .filter(a => !a?.assignment.archived)
          return reviews
    }

  },
  // watch: {
  //   showAuditData (val) {
  //     if (!val) {
  //       this.selectedCriteria = null
  //       this.$refs['view-container'].style.width = '400px'
  //     }
  //   }
  // },
  methods: {
    ...mapActions({
      fetchUsers: 'organisation/fetchUsers',
      fetchAssignments: 'assignment/fetchAssignments'
    }),
    viewAuditData (review) {
      this.review = review
      this.showAuditData = true
    },
    viewSectionCriteria (section) {
      this.$refs['view-container'].style.width = '850px'
      this.selectedCriteria = section
      this.selectedCriteria.totalCriteria = Object.keys(
        section.criteria
      ).reduce(
        (accum, current) => (accum += section.criteria[current].length),
        0
      )
    },
    // color (grade) {
    //   const style = {
    //     color: 'white',
    //     background: null
    //   }
    //   style.background =
    //     grade.determination && (grade.id !== 'qa' || grade.id !== 'additional')
    //       ? this.names[grade.determination].color
    //       : '#80C088'
    //   return style
    // },
    didSelectGradeKey (key) {
      console.log({ key })
    }
  },
  async mounted () {
    if (!this.assignments.length) {
      await this.fetchAssignments()
    }
    if (!this.users.length) {
      await this.fetchUsers()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

span.icon {
  bottom: 0rem;
}

.modal-content.section-data {
  // min-width: 90vw;
  width: 400px;
  // min-width: 400px;
  // max-width: 0;
  display: grid;
  grid-template-areas:
    "title title"
    "grades criteria";
  column-gap: 0.5rem;
  transition: all 300ms;
}

.modal-content.section-data > .title {
  grid-area: title;
  margin-bottom: 1rem;
}

.sections-container {
  grid-area: grades;
  padding: 0.5rem;
}
.section-criteria {
  grid-area: criteria;
  padding: 0.5rem;
  ul {
    list-style: none;
    padding-inline-start: 1rem;
    span {
      font-size: 1rem;
      text-decoration: underline;
    }
    li {
      font-size: 0.875rem;
      padding: 0.5rem 0;
    }
  }
}

.section-criteria > p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.section-comments {
  font-size: 0.9rem !important;
  text-align: left;
  padding-top: 1rem;
  font-size: 400;
  font-style: italic;
}

.section-comments::before {
  content: "Comments: ";
  font-style: normal;
}

.section-item {
  width: 100%;
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 0.5rem;
  div {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      padding: 6px 8px;
      border-radius: 30px;
      text-transform: uppercase;
      background: #f2f2f2;
    }
  }
  .section-id {
    justify-self: center;
  }
  .section-grade {
    padding-left: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// div.select {
//   padding-left: 0.5rem;
// }
.select select {
  outline: 0;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  height: 100%;
  // background-color: unset;
  // border: unset;
  border-radius: 4px;
  color: #363636;
  width: 100%;
  font-size: 1rem;
  padding: 4px;
  // margin-right: 10px;
  font-family: AllRoundGothicBook;
  font-weight: 600;
  // text-align: center;
  // text-align-last: center;
  // -moz-text-align-last: center;
}

td.align-left {
  padding-left: 1rem;
  text-align: left;
}
// .select select:focus {
//   min-width: 150px;
//   width: auto;
// }
</style>
