<template>
  <div class="custom-graph">
    <!-- <h3 class="subtitle">{{ title }}</h3> -->
    <div class="graph-area">
      <slot name="graph-area"></slot>
    </div>
    <div class="options-select">
      <slot name="options-select"></slot>
    </div>
    <div class="graph-options-container">
      <div class="graph-options">
        <div class="option-radio">
          <input type="radio" value="all" v-model="keyStageKey" /> All
        </div>
        <div class="option-radio" v-for="keyStage in sortedKeyStages" :key="keyStage">
          <input type="radio" :value="keyStage" v-model="keyStageKey" />
          {{ keyStage }}
        </div>
      </div>
      <div class="graph-options">
            <div class="option-radio">
              <input type="radio" value="all" v-model="period" />
              All
            </div>
            <div
              class="option-radio"
              v-for="option in periods"
              :key="option.value"
            >
              <input
                type="radio"
                :value="option.value"
                v-model="period"
              />
              {{ option.label }}
            </div>
          </div>
    </div>
  </div>
</template>

<script>

import subQuarters from 'date-fns/subQuarters'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfYear from 'date-fns/endOfYear'
import startOfYear from 'date-fns/startOfYear'
import endOfQuarter from 'date-fns/endOfQuarter'
import subYears from 'date-fns/esm/subYears/index.js'
import getQuarter from 'date-fns/getQuarter/index.js'


export default {
  name: 'CustomGraph',
  props: {
    title: String,
    keyStages: Set
  },
  computed: {
    sortedKeyStages () {
      return Array.from(this.keyStages).slice().sort()
    },
    periods () {
      const periods = []
      const now = new Date()
      const lastYear = subYears(now, 1)
      periods.push({
        start: startOfYear(lastYear),
        end: endOfYear(lastYear),
        label: lastYear.getFullYear()
      })
      function getLastQuarter (date) {
        const dayInLastQtr = subQuarters(date, 1)
        return {
          start: startOfQuarter(dayInLastQtr),
          end: endOfQuarter(dayInLastQtr),
          label: `${dayInLastQtr.getFullYear()} Q${getQuarter(dayInLastQtr)}`
        }
      }
      var qtrDay = new Date()
      while (periods.length < 6) {
        var lastQtr = getLastQuarter(qtrDay)
        qtrDay = lastQtr.start
        periods.push(lastQtr)
      }
      return periods.map(({ start, end, label }) => ({ value: JSON.stringify([start.toString(), end.toString()]), label }))
  }
  },
  data () {
    return {
      keyStageKey: 'all',
      period: {
        pan: 'all',
        section: 'all',
        input: 'all'
      },
    }
  },
  watch: {
    keyStageKey (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:key-stage', newVal)
      }
    },
    period (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:period', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.custom-graph {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr;
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-areas:
    "graph select select"
    "graph options options";
  .graph-area {
    grid-area: graph;
  }
  .options-select {
    justify-content: flex-start;
    grid-area: select;
  }
  // .subtitle {
  //   font-size: 1rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: 600;
  //   color: #484846;
  //   grid-area: subtitle;
  // }
  .graph-options-container {
    grid-area: options;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: flex-start;
    justify-content: center;
    .graph-options {
      grid-area: unset;
    }
  }
}
</style>