<template>
  <DashboardLayout>
    <template v-slot:sidebar>
      <SideBar :items="sidebarItems" />
    </template>
    <template v-slot:topnav>
      <TopNav :role="role" />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/components/common/DashboardLayout.vue'
import SideBar from '@/components/nav/SideBar.vue'
import TopNav from '@/components/nav/TopNav.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: { DashboardLayout, SideBar, TopNav },
  data () {
    return {
      sidebarItems: [
        {
          text: 'Dashboard',
          route: '/reports/dashboard',
        },
        {
          text: 'Custom Graphs',
          route: '/reports/custom',
        },
        {
          text: 'Query Builder',
          route: '/reports/queries',
        },
        {
          text: 'Further Data',
          route: '/reports/gradings',
        },
        {
          text: 'Theme Comments',
          route: '/reports/themecomments',
        },
        {
          text: 'Explanatory Comments',
          route: '/reports/explanatorycomments',
        },
        {
          text: 'Outcomes Monitoring',
          route: '/reports/outcomesmonitoring',
        },
        {
          text: 'Experiential Data',
          route: '/reports/experiential',
        },
        {
          text: 'Export Audits',
          route: '/reports/exportaudits',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      role: 'user/role'
    })
  },
  methods: {
    ...mapActions({
      fetchAssignmentsForCurrentUser: 'assignment/fetchAssignmentsForCurrentUser'
    })
  },
  async mounted () {
    await this.fetchAssignmentsForCurrentUser()
  }
}
</script>
