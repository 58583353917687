<template>
  <div class="further-data">
    <SectionHeader
      title="Experiential Data"
      subtitle="Feedback form responses - click a graph's title to edit"
    >
      <template v-slot:searchbar>
        <button class="button" @click="exportToCSV">
          Download comments
        </button>
      </template>
    </SectionHeader>
    <br />
    <div class="query-builder">
      <vue-query-builder
        :labels="queryLabels"
        :rules="queryRules"
        v-model="query"
        :maxDepth="1"
      ></vue-query-builder>
    </div>
    <!-- <div class="graphing" id="feedback-chart"></div> -->
    <div class="graphing" ref="form1-chart" id="form1-chart"></div>
    <div class="graphing" ref="form2-chart" id="form2-chart"></div>
    <div class="graphing" ref="form3-chart" id="form3-chart"></div>
  </div>
</template>

<script>
// what % of question x was agree/not agree, etc

import SectionHeader from "@/components/SectionHeader";
// import DataPopup from '@/views/admin/DataPopup.vue'

import { mapGetters, mapActions } from "vuex";

// import SearchUsersMixin from '@/_mixins/search-users.mixin'
// import DateFilterMixin from '@/_mixins/date-filter.mixin'
import OutcomesMixin from "@/_mixins/outcome-formatter.mixin";

import VueQueryBuilder from "vue-query-builder";
import jsonexport from "jsonexport";

import { outcomeGrades, formatGrades, getGradingColor  } from '../../_helpers/grading'


const operators = {
  "Equal To": (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf() &&
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  "Not Equal To": (x, y) =>
    x <= new Date(y).setHours(0, 0, 0, 0).valueOf() ||
    x >= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  "Less Than": (x, y) => x < new Date(y).setHours(0, 0, 0, 0).valueOf(),
  "Less Than Or Equal To": (x, y) =>
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  "Greater Than": (x, y) => x > new Date(y).setHours(24, 59, 59, 999).valueOf(),
  "Greater Than Or Equal To": (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf(),
};

const legendOrder = [
  "Strongly Agree",
  "Agree",
  "Unsure",
  "Disagree",
  "Strongly Disagree",
];
const questionOrder = ["Q1", "Q2", "Q3", "Q4", "Q5"];

export default {
  name: "ExperientialData",
  components: { SectionHeader, VueQueryBuilder },
  // mixins: [SearchUsersMixin, DateFilterMixin],
  mixins: [OutcomesMixin],
  data() {
    return {
      chart: {
        layout: {
          barmode: "stack",
          barnorm: "fraction",
          xaxis: {
            // autorange: false,
            title: "Question Number",
          },
          yaxis: {
            // autorange: false,
            // range: [0, 1],
            title: null,
            tickformat: "%",
          },
          // legend: {
          //   traceorder: 'normal',
          //   font: {
          //     family: 'AllRoundGothicBook',
          //     size: '16'
          //   }
          // },
        },
        config: {
          displaylogo: false,
          // responsive: true,
          // autosizable: true,
          editable: true,
        },
        options: {
          transition: {
            duration: 300,
            easing: "elastic-in-out",
          },
          frame: {
            duration: 300,
          },
        },
      },
      queryLabels: {
        matchTypes: [
          { id: "all", label: "Match all (AND)" },
          { id: "any", label: "Match any (OR)" },
        ],
        addRule: "Add filter",
        addGroup: "Add filter group",
      },
      queryRules: [
        {
          type: "select",
          id: "reviewComplete",
          label: "AR Complete",
          choices: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
        },
        {
          type: "select",
          id: "outcome",
          label: "Overall grading",
          choices: [
            { label: "Gold", value: "Gold" },
            { label: "Silver", value: "Silver" },
            { label: "Bronze", value: "Bronze" },
          ],
        },
        {
          type: "select",
          id: "theme",
          label: "Theme",
          choices: [
            { label: "Timeliness & Quality", value: 1 },
            { label: "Meeting Needs", value: 2 },
            { label: "Outcomes", value: 3 },
            { label: "Provision", value: 4 },
            { label: "Post-annual review", value: 5 },
            { label: "Outcomes monitoring", value: 6 },
          ],
        },
        {
          type: "select",
          id: "themeGrade",
          label: "Theme grade",
          choices: [
            { label: "Gold", value: "Gold" },
            { label: "Silver", value: "Silver" },
            { label: "Bronze", value: "Bronze" },
          ],
        },
        {
          type: "numeric",
          id: "completedAt",
          label: "Review completed",
          inputType: "date",
          operators: Object.keys(operators),
        },
        {
          type: "numeric",
          id: "createdAt",
          label: "Plan Uploaded",
          inputType: "date",
          operators: Object.keys(operators),
        },
      ],
      query: {
        children: [],
        logicalOperator: "all",
      },
      filters: [],
    };
  },
  watch: {
    query: {
      handler(val) {
        this.filters = this.query.children.slice();
        // if (Object.keys(this.formResponseTrace).length > 0) {
        this.refreshGraphs();
        // }
      },
      deep: true,
    },
    // queryData: {
    //   handler () {
    //     if (Object.keys(this.formResponseTrace).length > 0) {
    //       this.refreshGraphs()
    //     }
    //   },
    //   deep: true
    // }
  },
  computed: {
    ...mapGetters({
      feedback: "feedback/feedback",
      feedbackStale: "feedback/needsUpdating",
      surveys: "feedback/surveys",
      users: "organisation/users",
      user: "user/user",
      reviews: "assignment/reviews",
    }),
    organisationId() {
      return this.user.organisationId;
    },
    feedbackFormScores() {
      return [...this.feedback].map((form) => {
        return {
          ...form,
          total: form.answers.reduce(
            (accum, current) =>
              typeof current === "number" ? (accum += current) : accum,
            0
          ),
          score:
            form.answers.reduce(
              (accum, current) =>
                typeof current === "number" ? (accum += current) : accum,
              0
            ) / form.answers.length,
        };
      });
    },
    needs() {
      return new Set(this.feedbackFormScores.map((a) => a.plan.primaryNeed));
    },
    inputs() {
      return new Set(
        this.feedbackFormScores.map((a) => a.plan.currentSocialCare)
      );
    },
    keyStages() {
      return new Set(this.feedbackFormScores.map((a) => a.plan.keyStage));
    },
    settingTypes() {
      return new Set(this.feedbackFormScores.map((a) => a.plan.settingType));
    },
    settingNames() {
      return new Set(this.feedbackFormScores.map((a) => a.plan.settingName));
    },
    caseOfficers() {
      return new Set(this.feedbackFormScores.map((a) => a.plan.caseOfficer));
    },
    teamRegions() {
      return new Set(
        this.feedbackFormScores.map((a) => a.plan.teamRegion || "none")
      );
    },
    queryData() {
      const filters = this.filters.map(({ query }) => {
        return {
          rule: query.rule,
          value: query.value,
          operator: query.operator || null,
        };
      });

      // Rules that aren't just checked for in plan.
      const customRules = [
        "reviewComplete",
        "themeGrade",
        "outcome",
        "completedAt",
        "createdAt",
        "theme",
      ];
      let forms = [...this.feedbackFormScores];

      filters.forEach((filter) => {
        switch (true) {
          // Simple plan prop filters.
          case !customRules.includes(filter.rule):
            forms = forms.filter((f) => f.plan[filter.rule] === filter.value);
            break;

          // Has a completed review or not.
          case filter.rule === "reviewComplete":
            console.log({ filter });
            forms = forms.filter((f) =>
              filter.value === "yes" ? f.review !== null : f.review === null
            );
            break;

          // Has a certain outcome.
          case filter.rule === "outcome":
            forms = forms.filter(
              (f) => this.determineOutcome(f.review?.outcome) === filter.value
            );
            break;

          // We deliberately skip themeGrade as it's used with this filter.
          case filter.rule === "theme":
            // Get all seleted themeGrade filters.
            const themeGradeFilters = filters.filter(
              (f) => f.rule === "themeGrade"
            );
            if (!themeGradeFilters?.length) return forms;

            forms = forms.filter((f) => {
              // Get raw outcome for this theme.
              const rawOutcome = f.review?.answers?.find(
                (a) => a.theme === filter.value
              )?.outcome;
              if (!rawOutcome) return false;

              // Check against the selected themeGrades.
              if (
                themeGradeFilters
                  ?.map((f) => f.value)
                  .includes(this.determineOutcome(rawOutcome))
              )
                return true;
            });
            break;

          // Handle both date filters, with operators.
          case filter.rule === "completedAt":
          case filter.rule === "createdAt":
            const filterDate = new Date(filter.value);
            forms = forms.filter((feedback) => {
              const feedbackDate = new Date(feedback[filter.rule]);
              return operators[filter.operator](
                feedbackDate.valueOf(),
                filterDate.valueOf()
              );
            });
            break;
        }
      });

      return forms;
    },
    formResponses() {
      return this.queryData.reduce((accumulator, feedback, idx) => {
        if (!accumulator.hasOwnProperty(feedback.type)) {
          accumulator = {
            ...accumulator,
            [feedback.type]: {},
          };
        }

        const answers = feedback.answers
          .map(this.formatAnswerScore)
          .filter((a) => a);

        answers.forEach((answer, index) => {
          if (!accumulator[feedback.type].hasOwnProperty(answer)) {
            accumulator[feedback.type][answer] = {
              [index]: 0,
            };
          } else if (
            !accumulator[feedback.type][answer].hasOwnProperty(index)
          ) {
            accumulator[feedback.type][answer] = {
              ...accumulator[feedback.type][answer],
              [index]: 0,
            };
          }
          ++accumulator[feedback.type][answer][index];
        });
        return accumulator;
      }, {});

      // Object.keys(values)
      //   .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
      //   .forEach(k => (ordered[k] = values[k]))
    },
    formResponseTrace() {
      let trace = {};
      Object.keys(this.formResponses).forEach((type) => {
        const possibleAnswers = Object.keys(this.formResponses[type]);
        trace = { ...trace, [type]: [] };
        possibleAnswers.forEach((answer) => {
          const questions = Object.keys(this.formResponses[type][answer])
            .map((q) => Number(q) + 1)
            .sort();

          const frequency = Object.values(this.formResponses[type][answer]);

          const data = {
            name: answer,
            x: questions,
            y: frequency,
            type: "bar",
            // mode: 'markers',
            textposition: "auto",
            text: frequency.map(String),
            marker: {
              color: this.formatAnswerColor(answer),
            },
          };
          trace[type].push(data);
        });
      });
      return trace;
    },
    carerTrace() {
      if (!this.formResponseTrace.hasOwnProperty("carer_feedback")) {
        return null;
      }
      return [this.sortLegends(this.formResponseTrace["carer_feedback"])];
    },
    cyp1Trace() {
      if (!this.formResponseTrace.hasOwnProperty("child_feedback")) {
        return null;
      }
      return [this.sortLegends(this.formResponseTrace["child_feedback"])];
    },
    cyp2Trace() {
      if (!this.formResponseTrace.hasOwnProperty("child_feedback_2")) {
        return null;
      }
      return [this.sortLegends(this.formResponseTrace["child_feedback_2"])];
    },
  },
  methods: {
    ...mapActions({ fetchFeedback: "feedback/getAllFeedbackData" }),
    async exportToCSV() {
      try {
        /* Answer bitmaps. */
        const dataMap = {
          6: "Question comments",
          5: "Strongly Agree",
          4: "Agree",
          3: "Unsure",
          2: "Disagree",
          1: "Strongly disagree",
        };

        /**
         * Converts an array to a obj for jsonexport rows.
         */
        const headers = [
            "1. I was informed in advance the annual review meeting was taking place and was asked for my views",
            "1. Comments",
            "2. I had been informed of the purpose of the annual review meeting and the process",
            "2. Comments",
            "3. My views and feelings were seen as important in this process and I was fully included in the meeting",
            "3. Comments",
            "4. The Annual Review meeting had clear outcomes / next steps",
            "4. Comments",
            "5. I feel the annual meeting was positive",
            "5. Comments",
            "6. Any other comments (optional)",
            "7. How do you currently receive information about help and support for special education needs",
            "8. Where do you go for general news and information?",
            "9. What would you like to find out more about in terms of the services you receive?",
            "10. How would you like to receive information through on the services you receive?",
        ];

        /**
         * Parse the data into a row format.
         */
        const data = this.queryData.map(({ answers, feedback }) => {
          // Default the feedback object (only found in new feedback).
          if (!feedback) feedback = {};
          
          return answers.flatMap((val, i) => {
            // Default value.
            if (!val) return i < 5 ? ["", ""] : "";

            // For the first 5, we're mapping.
            if (val in dataMap) {
              val = dataMap[val];
            } else {
              // The last five aren't mapped values, but free text comments.
              val = val ? val.replaceAll(",", "") : "";
            }

            // The first 5 also have comments in feedback.
            if (i < 5) return [val, (feedback[i] || "").replaceAll(",", "")];
            return val;
          }).reduce((obj, val, _i) => { obj[headers[_i]] = val; return obj; }, {})
        });

        const doc = await jsonexport(data, { headers });
        const newBlob = new Blob([doc]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(doc);
      } catch (e) {
        console.error(e);
      }
    },
    reviewField(row) {
      return row.review.outcome;
    },
    planField(row) {
      return row.plan.internalId;
    },
    sortLegends(input) {
      return [...input].sort(
        (a, b) => legendOrder.indexOf(b.name) - legendOrder.indexOf(a.name)
      );
    },
    formatAnswers(answers, row) {
      const type = [...this.surveys].find((s) => s.type === row.type);
      return answers.map((answer, index) => {
        const question = type.questions[index];
        if (question.type === "input") {
          return answer;
        }
        return question.ratingScale.find((r) => r.value === answer).label;
      });
    },
    formatOutcome(row) {
      const val = row.outcome;
      const color = getGradingColor(val, outcomeGrades, 'Bronze')

      return color
    },
    formatType(type) {
      if (type === "carer_feedback") {
        return "Carer Feedback";
      } else if (type === "child_feedback") {
        return "C/YP Feedback 1";
      } else if (type === "child_feedback_2") {
        return "C/YP Feedback 2";
      }
    },
    formatAnswerScore(score) {
      const answers = {
        5: "Strongly Agree",
        4.5: "Agree",
        4: "Agree",
        3: "Unsure",
        2: "Disagree",
        1.5: "Disagree",
        1: "Strongly Disagree",
      };
      return answers[score];
    },
    formatAnswerColor(answer) {
      const colours = {
        "Strongly Agree": "rgb(0,50,100)",
        Agree: "rgb(50,50,160)",
        Unsure: "rgb(0,180,185)",
        Disagree: "rgb(220, 20, 155)",
        "Strongly Disagree": "rgb(255,0,80)",
      };
      return colours[answer];
    },
    formatTotalScore(total) {
      const answers = {
        "Wholly positive": {
          min: 20,
          max: 25,
        },
        "Generally positive": {
          min: 15,
          max: 19.999,
        },
        "Neither positive nor negative": {
          min: 10,
          max: 14.999,
        },
        "Generally negative": {
          min: 5,
          max: 9.999,
        },
        "Wholly negative": {
          min: 0,
          max: 4.999,
        },
      };
      let formattedAnswer;
      Object.keys(answers).forEach(
        (answer) =>
          (formattedAnswer =
            answers[answer].min <= total && total <= answers[answer].max
              ? answer
              : formattedAnswer)
      );
      return formattedAnswer;
    },
    async refreshGraphs() {
      // Plotly.react(
      //   this.$refs['feedback-chart'],
      //   [...this.formResponseTrace['carer_feedback']],
      //   { title: 'Carer Feedback', barmode: 'stack', xaxis: { title: 'Question Number' }, yaxis: { title: 'No. responses' } },
      //   { displaylogo: false, responsive: true, autosizable: true, editable: true })
      // this.$nextTick(() => console.log({ ...this.formResponseTrace }))
      // this.$nextTick(() => console.log({ ...this.queryData }))
      // console.log({ ...this.formResponseTrace })
      // console.log({ ...this.chart.layout, title: 'Carer Feedback' })
      // console.log({ ...this.chart.config })
      const form1Data = this.carerTrace || [];
      // console.log([...form1Data])
      await Plotly.react(
        this.$refs["form1-chart"],
        ...form1Data,
        { ...this.chart.layout, title: "Carer Feedback" },
        { ...this.chart.config }
        // { ... this.chart.options }
        // ).then(console.log)
      );
      const form2Data = this.cyp1Trace || [];
      Plotly.react(
        this.$refs["form2-chart"],
        ...form2Data,
        { ...this.chart.layout, title: "C/YP Feedback - Older Children" },
        { ...this.chart.config }
        // },
        // { ... this.chart.options }
      );
      const form3Data = this.cyp2Trace || [];
      Plotly.react(
        this.$refs["form3-chart"],
        ...form3Data,
        { ...this.chart.layout, title: "C/YP Feedback - Younger Children" },
        { ...this.chart.config }
        // },
        // { ... this.chart.options }
      );
    },
    pushQueryRules() {
      const settingNames = {
        type: "select",
        id: "settingName",
        label: "Setting name",
        choices: Array.from(this.settingNames).map((t) => {
          return { label: t, value: t };
        }),
      };
      const settingTypes = {
        type: "select",
        id: "settingType",
        label: "Setting type",
        choices: Array.from(this.settingTypes).map((t) => {
          return { label: t, value: t };
        }),
      };
      const inputs = {
        type: "select",
        id: "currentSocialCare",
        label: "Social Care Input",
        choices: Array.from(this.inputs).map((t) => {
          return { label: t, value: t };
        }),
      };
      const needs = {
        type: "select",
        id: "primaryNeed",
        label: "Primary area of need",
        choices: Array.from(this.needs).map((t) => {
          return { label: t, value: t };
        }),
      };
      const stages = {
        type: "select",
        id: "keyStage",
        label: "Key Stage",
        choices: Array.from(this.keyStages).map((t) => {
          return { label: t, value: t };
        }),
      };
      const officers = {
        type: "select",
        id: "caseOfficer",
        label: "SEN Case Officer",
        choices: Array.from(this.caseOfficers).map((t) => {
          return {
            label: t === undefined || t === null ? "none assigned" : t,
            value: t,
          };
        }),
      };
      const teamRegions = {
        type: "select",
        id: "teamRegion",
        label: "Team / Region",
        choices: Array.from(this.teamRegions).map((t) => {
          return {
            label: t === undefined || t === null ? "none assigned" : t,
            value: t,
          };
        }),
      };
      this.queryRules.push(settingNames);
      this.queryRules.push(settingTypes);
      this.queryRules.push(inputs);
      this.queryRules.push(needs);
      this.queryRules.push(stages);
      this.queryRules.push(officers);
      this.queryRules.push(teamRegions);
    },
  },
  async mounted() {
    // if (this.feedbackStale) {
    await this.fetchFeedback(this.organisationId);
    // }

    this.pushQueryRules();

    Plotly.newPlot(
      this.$refs["form1-chart"],
      ...this.carerTrace,
      { ...this.chart.layout, title: "Carer Feedback" },
      { ...this.chart.config }
    );
    Plotly.newPlot(
      this.$refs["form2-chart"],
      ...this.cyp1Trace,
      { ...this.chart.layout, title: "C/YP Feedback - Older Children" },
      { ...this.chart.config }
    );
    Plotly.newPlot(
      this.$refs["form3-chart"],
      ...this.cyp2Trace,
      { ...this.chart.layout, title: "C/YP Feedback - Younger Children" },
      { ...this.chart.config }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

span.icon {
  bottom: 0rem;
}

.modal-content.section-data {
  // min-width: 90vw;
  width: 400px;
  // min-width: 400px;
  // max-width: 0;
  display: grid;
  grid-template-areas:
    "title title"
    "grades criteria";
  column-gap: 0.5rem;
  transition: all 300ms;
}

.modal-content.section-data > .title {
  grid-area: title;
  margin-bottom: 1rem;
}

.sections-container {
  grid-area: grades;
  padding: 0.5rem;
}
.section-criteria {
  grid-area: criteria;
  padding: 0.5rem;
  ul {
    list-style: none;
    padding-inline-start: 1rem;
    span {
      font-size: 1rem;
      text-decoration: underline;
    }
    li {
      font-size: 0.875rem;
      padding: 0.5rem 0;
    }
  }
}

.section-criteria > p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.section-comments {
  font-size: 0.9rem !important;
  text-align: left;
  padding-top: 1rem;
  font-size: 400;
  font-style: italic;
}

.section-comments::before {
  content: "Comments: ";
  font-style: normal;
}

.section-item {
  width: 100%;
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 0.5rem;
  div {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      padding: 6px 8px;
      border-radius: 30px;
      text-transform: uppercase;
      background: #f2f2f2;
    }
  }
  .section-id {
    justify-self: center;
  }
  .section-grade {
    padding-left: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// div.select {
//   padding-left: 0.5rem;
// }
.select select {
  outline: 0;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  height: 100%;
  // background-color: unset;
  // border: unset;
  border-radius: 4px;
  color: #363636;
  width: 100%;
  font-size: 1rem;
  padding: 4px;
  // margin-right: 10px;
  font-family: AllRoundGothicBook;
  font-weight: 600;
  // text-align: center;
  // text-align-last: center;
  // -moz-text-align-last: center;
}

td.align-left {
  padding-left: 1rem;
  text-align: left;
}
// .select select:focus {
//   min-width: 150px;
//   width: auto;
// }
</style>

<style lang="scss">
.vue-query-builder .vqb-group .rule-actions,
div.vqb-rule.card {
  margin-bottom: unset;
}
</style>
