<template>
  <div class="custom-graphs">
    <SectionHeader title="Outcomes (long term) Monitoring Graphs" />
    <div class="graphs">
      <div class="query-graph">
        <div class="query-builder">
          <vue-query-builder
            :labels="queryLabels"
            :rules="queryRules"
            v-model="query"
            :maxDepth="1"
          ></vue-query-builder>
        </div>
        <div class="graphing" ref="graph-query"></div>
      </div>
      <div class="graphing" ref="graph-input"></div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import CustomGraph from './CustomGraph'
import OutcomesMixin from '@/_mixins/outcome-formatter.mixin'
import VueQueryBuilder from 'vue-query-builder'
import { mapGetters, mapActions } from 'vuex'
import { outcomeGrades, formatGrades, getGradingColor  } from '../../_helpers/grading'


const graphFont = {
  family: 'AllRoundGothicBook',
  size: '16',
  color: 'white'
}

const labelOrder = ['Exceeded target', 'Achieved target', 'Unknown', 'Not achieved target', 'On track to fully achieve', 'On track to partially achieve', 'Not on track to achieve', 'Likely to exceed']

const operators = {
  'Equal To': (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf() &&
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Not Equal To': (x, y) =>
    x <= new Date(y).setHours(0, 0, 0, 0).valueOf() ||
    x >= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Less Than': (x, y) => x < new Date(y).setHours(0, 0, 0, 0).valueOf(),
  'Less Than Or Equal To': (x, y) =>
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Greater Than': (x, y) => x > new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Greater Than Or Equal To': (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf()
}


export default {
  name: 'OutcomesMonitoringGraphs',
  components: { SectionHeader, CustomGraph, VueQueryBuilder },
  mixins: [OutcomesMixin],
  data () {
    return {
      type: 'bar',
      layout: {
        barnorm: 'fraction',
        yaxis: {
          title: null,
          tickformat: '%'
        },
        legend: {
          traceorder: 'normal',
          font: {
            family: 'AllRoundGothicBook',
            size: '16'
          }
        }
      },
      queryLabels: {
        matchTypes: [
          { id: 'all', label: 'Match all (AND)' },
          { id: 'any', label: 'Match any (OR)' }
        ],
        addRule: 'Add filter',
        addGroup: 'Add filter group'
      },
      queryRules: [
        {
          type: 'select',
          id: 'outcome',
          label: 'Overall grading',
          choices: [
            { label: 'Gold', value: 'Gold' },
            { label: 'Silver', value: 'Silver' },
            { label: 'Bronze', value: 'Bronze' }
          ]
        },
        {
          type: 'select',
          id: 'theme',
          label: 'Theme',
          choices: [
            { label: 'Timeliness & Quality', value: 1 },
            { label: 'Meeting Needs', value: 2 },
            { label: 'Outcomes', value: 3 },
            { label: 'Provision', value: 4 },
            { label: 'Post-annual review', value: 5 },
            { label: 'Outcomes monitoring', value: 6 }
          ]
        },
        {
          type: 'numeric',
          id: 'completedAt',
          label: 'Review completed',
          inputType: 'date',
          operators: Object.keys(operators)

        },
        {
          type: 'numeric',
          id: 'createdAt',
          label: 'Plan Uploaded',
          inputType: 'date',
          operators: Object.keys(operators)

        }
      ],
      query: {
        children: [
        ],
        logicalOperator: 'all'
      },
      filters: []
    }
  },
  watch: {
    inputKeyStageKey (newVal) {
      Plotly.animate(this.$refs['graph-input'],
        {
          data: this.careInputTrace,
          layout: this.layout,
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )
      Plotly.animate(this.$refs['graph-query'],
        {
          data: this.queryTrace,
          layout: this.layout,
          config: { responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )
    },
    'query.children': {
      handler () {
        this.filters = this.query.children.slice()
        this.refreshGraphs()
      },
      deep: true
    },
    type () {
      this.$forceUpdate()
      this.$nextTick(() => this.refreshGraphs())
    }
  },
  computed: {
    ...mapGetters({ reviews: 'assignment/reviews' }),
    needs () {
      return new Set(this.reviews.map(r => r.assignment.plan.primaryNeed))
    },
    keyStages () {
      return new Set(this.reviews.map(r => r.assignment.plan.keyStage))
    },
    completedReviews () {
      const reviews = this.reviews.filter(r => r.completedAt && r.outcome && !r?.assignment?.archived)
      return reviews
    },
    careInputDataFromQuery () {
      const hasOutComeQuery = this.filters.some(f => f.query.rule === 'outcome')
      const hasDateQuery = this.filters.filter(f => f.query.rule === 'completedAt') || false
      const hasDateUploadedQuery = this.filters.filter(f => f.query.rule === 'createdAt') || false

      const filters = this.filters.map(({ query }) => {
        return {
          rule: query.rule,
          value: query.value,
          operator: query.operator || null
        }
      })
      let reviews = this.completedReviews
        .filter(r => r.answers.find(a => a.theme == 6)?.criteria?.length > 1)

      const excludedRules = []
      filters.forEach(filter => {
        if (!excludedRules.includes(filter.rule)) {
          reviews = reviews.filter(review => review.assignment.plan[filter.rule] === filter.value)
        } else if (hasOutComeQuery && filter.rule === 'outcome') {
          reviews = reviews.filter(review => this.determineOutcome(review.outcome) === filter.value)
        }
      })

      if (hasDateQuery.length > 0 || hasDateUploadedQuery.length > 0) {
        filters
          .filter(f => f.rule === hasDateQuery.length
            ? 'completedAt'
            : 'createdAt')
          .forEach(filter => {
            const filterDate = new Date(filter.value)
            reviews = reviews.filter(review => {
              const reviewDate = new Date(review[filter.rule])
              return operators[filter.operator](reviewDate.valueOf(), filterDate.valueOf())
            })
          })
      }
      return reviews
    },
    careInputData () {
      const reviews = this.careInputDataFromQuery
        .filter(r => r.answers.find(a => a.theme == 6).criteria.length > 1)
      const results = reviews.map(r => ({
        input: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 2)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 2).text
          : null,
        completedOutcome: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 4)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 4).text
          : null,
        expectedOutcome: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 5)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 5).text
          : null
      }))

      const inputs = Array.from(new Set(results.map(i => i.input)))
      inputs.unshift('Consolidated')
      const outcomes = Array.from(new Set(results.map(i => i.completedOutcome)))
        .filter(o => o !== null)
      const totals = {
        outcomes: ['Achieved target', 'Exceeded target', 'Not achieved target', 'Unknown'],
        achieved: results.filter(r => r.completedOutcome === 'Achieved target').length || 0,
        exceeded: results.filter(r => r.completedOutcome === 'Exceeded target').length || 0,
        notAchieved: results.filter(r => r.completedOutcome === 'Not achieved target').length || 0,
        unknown: results.filter(r => r.completedOutcome === 'Unknown').length || 0,
      }

      const traces = []
      let frequency = 0
      outcomes.forEach(outcome => {
        if (!outcome) {
          return
        }
        ++frequency
        const outcomeObject = {
          x: [],
          y: [],
          name: outcome,
          type: 'bar',
          sort: false,
          textposition: 'auto',
          textfont: graphFont,
          hoverlabel: {
            bordercolor: this.formatAnswerColor(outcome),
            font: graphFont
          },
          marker: {
            color: this.formatAnswerColor(outcome)
          }
        }
        inputs.forEach(input => {
          if (input === 'Consolidated') {
            outcomeObject.x.push(input)
            if (outcome === 'Achieved target') {
              outcomeObject.y.push(totals.achieved)
            } else if (outcome === 'Exceeded target') {
              outcomeObject.y.push(totals.exceeded)
            } else if (outcome === 'Not achieved target') {
              outcomeObject.y.push(totals.notAchieved)
            } else if (outcome === 'Unknown') {
              outcomeObject.y.push(totals.unknown)
            }
          } else if (input !== 'Total') {
            outcomeObject.x.push(input)
            outcomeObject.y.push(results.filter(r => (r.input === input) && r.completedOutcome === outcome).length || 0)
          }
        })
        outcomeObject.text = outcomeObject.y
        traces.push(outcomeObject)
      })
      return traces
        .sort((a, b) => labelOrder.indexOf(a.name) - labelOrder.indexOf(b.name))
    },
    careInputTrace () {
      return this.careInputData
    },
    graphDataFromQuery () {
      const hasOutComeQuery = this.filters.some(f => f.query.rule === 'outcome')
      const hasDateQuery = this.filters.filter(f => f.query.rule === 'completedAt') || false
      const hasDateUploadedQuery = this.filters.filter(f => f.query.rule === 'createdAt') || false

      const filters = this.filters.map(({ query }) => {
        return {
          rule: query.rule,
          value: query.value,
          operator: query.operator || null
        }
      })

      let reviews = this.completedReviews
        .filter(r => r.answers.find(a => a.theme == 6)?.criteria?.length > 1)

      const excludedRules = ['outcome', 'completedAt', 'createdAt']
      filters.forEach(filter => {
        if (!excludedRules.includes(filter.rule)) {
          reviews = reviews.filter(review => review.assignment.plan[filter.rule] === filter.value)
        } else if (hasOutComeQuery && filter.rule === 'outcome') {
          reviews = reviews.filter(review => this.determineOutcome(review.outcome) === filter.value)
        }
      })



      if (hasDateQuery.length > 0 || hasDateUploadedQuery.length > 0) {
        filters
          .filter(f => f.rule === hasDateQuery.length
            ? 'completedAt'
            : 'createdAt')
          .forEach(filter => {
            const filterDate = new Date(filter.value)
            reviews = reviews.filter(review => {
              const reviewDate = new Date(review[filter.rule])
              return operators[filter.operator](reviewDate.valueOf(), filterDate.valueOf())
            })
          })
      }

      return reviews
    },
    queryChartData () {
      const results = this.graphDataFromQuery.map(r => ({
        input: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 2)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 2).text
          : null,
        outcome: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 4)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 4).text
          : null,
        expectedOutcome: r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 5)
          ? r.answers.find(a => a.theme == 6).criteria.find(a => a.questionIndex === 5).text
          : null
      }))

      const inputs = Array.from(new Set(results.map(i => i.input)))
      inputs.unshift('Consolidated')
      const outcomes = Array.from(new Set(results.map(i => i.expectedOutcome)))
        .filter(o => o !== null)
      const totals = {
        outcomes: ['On track to fully achieve', 'On track to partially achieve', 'Not on track to achieve', 'Likely to exceed'],
        onTrackFully: results.filter(r => r.expectedOutcome === 'On track to fully achieve').length || 0,
        onTrackPartially: results.filter(r => r.expectedOutcome === 'On track to partially achieve').length || 0,
        notOnTrack: results.filter(r => r.expectedOutcome === 'Not on track to achieve').length || 0,
        likelyExceed: results.filter(r => r.expectedOutcome === 'Likely to exceed').length || 0,
      }
      const traces = []
      let frequency = 0
      outcomes.forEach(outcome => {
        ++frequency
        const outcomeObject = {
          x: [],
          y: [],
          name: outcome,
          type: 'bar',
          sort: false,
          textposition: 'auto',
          textfont: graphFont,
          hoverlabel: {
            bordercolor: this.formatAnswerColor(outcome),
            font: graphFont
          },
          marker: {
            color: this.formatAnswerColor(outcome)
          }
        }
        inputs.forEach(input => {
          if (input === 'Consolidated') {
            outcomeObject.x.push(input)
            if (outcome === 'On track to fully achieve') {
              outcomeObject.y.push(totals.onTrackFully)
            } else if (outcome === 'On track to partially achieve') {
              outcomeObject.y.push(totals.onTrackPartially)
            } else if (outcome === 'Not on track to achieve') {
              outcomeObject.y.push(totals.notOnTrack)
            } else if (outcome === 'Likely to exceed') {
              outcomeObject.y.push(totals.likelyExceed)
            }
          } else {
            outcomeObject.x.push(input)
            outcomeObject.y.push(results.filter(r => r.input === input && r.expectedOutcome === outcome).length || 0)
          }
        })
        outcomeObject.text = outcomeObject.y
        traces.push(outcomeObject)
      })

      return traces
        .sort((a, b) => labelOrder.indexOf(a.name) - labelOrder.indexOf(b.name))
    },
    queryTrace () {
      return this.queryChartData
    },
    needs () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.primaryNeed))
    },
    inputs () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.currentSocialCare))
    },
    keyStages () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.keyStage))
    },
    settingTypes () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.settingType))
    },
    settingNames () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.settingName))
    },
    caseOfficers () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.caseOfficer))
    },
    teamRegions () {
      return new Set(this.completedReviews.map(a => a.assignment.plan.teamRegion))
    }
  },
  methods: {
    pushQueryRules () {
      const settingNames = {
        type: 'select',
        id: 'settingName',
        label: 'Setting name',
        choices: Array.from(this.settingNames).map(t => { return { label: t, value: t } })
      }
      const settingTypes = {
        type: 'select',
        id: 'settingType',
        label: 'Setting type',
        choices: Array.from(this.settingTypes).map(t => { return { label: t, value: t } })
      }
      const inputs = {
        type: 'select',
        id: 'currentSocialCare',
        label: 'Social Care Input',
        choices: Array.from(this.inputs).map(t => { return { label: t, value: t } })
      }
      const needs = {
        type: 'select',
        id: 'primaryNeed',
        label: 'Primary area of need',
        choices: Array.from(this.needs).map(t => { return { label: t, value: t } })
      }
      const stages = {
        type: 'select',
        id: 'keyStage',
        label: 'Key Stage',
        choices: Array.from(this.keyStages).map(t => { return { label: t, value: t } })
      }
      const officers = {
        type: 'select',
        id: 'caseOfficer',
        label: 'SEN Case Officer',
        choices: Array.from(this.caseOfficers).map(t => { return { label: t === undefined || t === null ? 'none assigned' : t, value: t } })
      }
      const teamRegions = {
        type: 'select',
        id: 'teamRegion',
        label: 'Team / Region',
        choices: Array.from(this.teamRegions).map(t => { return { label: t === undefined || t === null ? 'none assigned' : t, value: t } })
      }
      this.queryRules.push(settingNames)
      this.queryRules.push(settingTypes)
      this.queryRules.push(inputs)
      this.queryRules.push(needs)
      this.queryRules.push(stages)
      this.queryRules.push(officers)
      this.queryRules.push(teamRegions)
    },
    determineOutcome (val) {
      const color = getGradingColor(val, outcomeGrades, 'Bronze')

      return color
    },
    refreshGraphs () {
      Plotly.react(this.$refs['graph-query'],
        {
          data: this.queryTrace,
          layout: { title: 'Outcomes Monitoring (Specified Timeframe)', barmode: 'stack', barnorm: 'fraction', yaxis: { title: null, tickformat: '%' } },
          config: { editable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )

      Plotly.react(this.$refs['graph-input'],
        {
          data: this.careInputTrace,
          layout: { title: 'Outcomes Monitoring (Full Period)', barmode: 'stack', barnorm: 'fraction', yaxis: { title: null, tickformat: '%' } },
          config: { displaylogo: false, responsive: true, autosizable: true }
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        }
      )
    },
    formatAnswerColor (answer) {
      const colours = {
        'Exceeded target': 'rgb(0,50,100)',
        'Achieved target': 'rgb(50,50,160)',
        'Unknown': 'rgb(0,180,185)',
        'Not achieved target': 'rgb(255,0,80)',
        'Not on track to achieve': 'rgb(220, 20, 155)',
        'Likely to exceed': 'rgb(0,50,50)',
        'On track to fully achieve': 'rgb(0,50,160)',
        'On track to partially achieve': 'rgb(0,90,100)'
      }
      return colours[answer]
    }
  },
  async mounted () {
   // here we make a dispatch to refresh state on mount
   await this.$store.dispatch("assignment/fetchAssignmentsForCurrentUser");
    await this.pushQueryRules()
    Plotly.newPlot(
      this.$refs['graph-query'],
      this.queryTrace,
      { title: 'Outcomes Monitoring (Specified Timeframe)', barmode: 'stack', barnorm: 'fraction', yaxis: { title: null, tickformat: '%' } },
      { displaylogo: false, responsive: true, autosizable: true })

    Plotly.newPlot(
      this.$refs['graph-input'],
      this.careInputTrace,
      { title: 'Outcomes Monitoring (Full Period)', barmode: 'stack', barnorm: 'fraction', yaxis: { title: null, tickformat: '%' } },
      { displaylogo: false, responsive: true, autosizable: true }
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.options-select {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding-right: 1rem;
  }
  .select {
    select {
      display: inline-flex;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 2.5rem;
      background-color: #fff;
      border-color: #dbdbdb;
      border-radius: 4px;
      color: #363636;
      max-width: 100%;
      min-width: 150px;
      width: auto;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      padding: 0.25rem 1rem;
      line-height: 1.5;
    }
  }
}

.graphs {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 2rem;
}

.query-graph {
  margin-bottom: 3rem;
}
</style>
